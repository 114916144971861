import ApiRoot from './fetcher'
import apis from '@/framework/config/api-config'


export const userInfoByToken = async (input: any) => {
  try {
    const data = await ApiRoot({ url: `${process.env.REACT_APP_AUTHPATH}/graphql` }).users().userAttestation(input)
    return data
  } catch (e) {
    return false
  }
}







