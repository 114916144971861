/*
 * @Author: yuszhou yuszhou@deloitte.com.cn
 * @Date: 2023-02-21 10:51:40
 * @LastEditors: yuszhou yuszhou@deloitte.com.cn
 * @LastEditTime: 2023-03-21 21:23:43
 * @FilePath: \rc_china_storeportal_ma\src\lib\menus.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
interface MenuObject {
  name: string
  value: string
  url: string
  key: string // auth permission key.
  icon?: any
  activekey: string, // menu active key.
  langKey: string
  children?: MenuObject[]
}

export const menus: MenuObject[] = [
  {
    name: 'Quick Start',
    value: 'quickStart',
    url: '',
    key: 'quickStart',
    activekey: 'quickStart',
    icon: <span className='icon iconfont text-[18px] icon-quickstart' />,
    langKey: 'menu.quickStart',
  },
  {
    name: 'Campaign Plan',
    value: 'Campaign Plan',
    url: '/campaign-plan',
    key: "campaignPlan",
    activekey: 'campaign-plan',
    icon: <span className="icon iconfont text-xl icon-a-compaignplan" />,
    langKey: 'menu.Campaign plan'
  },
  {
    name: 'Smart Operation',
    value: 'Smart Operation',
    url: '/smart-operation',
    key: 'smartOperation',
    activekey: 'smart-operation',
    icon: <span className="icon iconfont text-xl icon-a-smartopersation" />,
    langKey: 'menu.Smart Operation',
    children: [
      {
        name: 'Quick Task',
        value: 'Quick Task',
        activekey: 'quick-task',
        url: '/smart-operation/quick-task',
        key: 'smartOperation.quickTask',
        langKey: 'menu.Quick Task',
      },
      {
        name: 'Process Canvas',
        value: 'Process Canvas',
        activekey: 'process-canvas',
        url: '/smart-operation/process-canvas',
        key: 'smartOperation.processCanvas',
        langKey: 'menu.Process Canvas',
      }
    ],
  },
  {
    name: 'Template Management',
    value: 'templateManagement',
    url: '/template-management',
    activekey: 'template-management',
    key: 'templates',
    icon: <span className="icon iconfont text-xl icon-bulkadd-copy" />,
    langKey: 'menu.templateManagement',
    children: [
      {
        name: 'Email List',
        value: 'emailList',
        url: '/template-management/email-list',
        key: 'templates.emailTemplate',
        langKey: 'menu.emailList',
        activekey: 'email-list',
      },
      {
        name: 'SMS List',
        value: 'smsList',
        url: '/template-management/sms-list',
        key: 'templates.smsTemplate',
        langKey: 'menu.smsList',
        activekey: 'sms-list',
      }
    ],
  },
  {
    name: 'Message Center',
    value: 'messageCenter',
    url: '/message-center',
    key: 'messageCenter',
    activekey: 'message-center',
    langKey: 'menu.messageCenter',
    icon: <span className="icon iconfont text-xl icon-Frame7" />,
    children: [
      {
        name: 'Message list',
        value: 'messagelist',
        url: '/message-center/message-list',
        key: 'messageCenter.messageList',
        activekey: 'message-list',
        langKey: 'menu.messagelist',
      }
    ]
  },
]

// export const initActive = (pathname: string) => {
//   let selectedKeys: string[] = []
//   menus.forEach((menu) => {
//     menu.children.forEach((subMenu) => {
//       if (subMenu.url === pathname) {
//         selectedKeys = [menu.key, subMenu.key]
//       }
//     })
//   })
//   return selectedKeys
// }
