/*
 * @Author: yuszhou
 * @Date: 2023-02-28 21:31:32
 * @LastEditTime: 2023-03-23 22:21:55
 * @LastEditors: yuszhou
 * @Description: 
 * @FilePath: \rc_china_storeportal_ma\src\store\lang.store.ts
 */
import { atom } from 'jotai'
import { TLangKey } from '@/locale'
import { getDataFormSeamCookie, getFormLocalstorage } from '@/utils/utils'
let defaultLocale: any = ''
try {
    defaultLocale = getDataFormSeamCookie("locale") as TLangKey;
} catch (error) {
    defaultLocale = null
}

export const langAtom = atom<TLangKey>(defaultLocale ?? 'en_US')