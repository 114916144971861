import { lazy } from 'react' // 路由懒加载
const ComingSoon = lazy(() => import('@/views/comingSoon'))
const Layout = lazy(() => import('../common/components/Layout'))
const Home = lazy(() => import('../views/index'))
const Login = lazy(() => import('../views/login'))
const LoginStore = lazy(() => import('../views/loginStore'))
const SelectStore = lazy(() => import('@/common/components/selectStore'))
const LoginBrand = lazy(() => import('../views/loginBrand'))
const ResetPassword = lazy(() => import('../views/resetPassword'))
const Register = lazy(() => import('../views/register'))
const EmailList = lazy(() => import('../views/emailList/list'))
const SMSList = lazy(() => import('../views/smsList/list'))
const AddOrEditEmail = lazy(() => import('../views/emailList/addAndEdit'))
const AddOrEditSMS = lazy(() => import('../views/smsList/addAndEdit'))
const CompaignPlan = lazy(() => import('../views/compaignPlan'))
const TaskCreateAndEdit = lazy(() => import('../views/smartOperation/addAndE'))
const MessageList = lazy(() => import('@/views/messageCenter/messageList'))
const MessageDetail = lazy(() => import('@/views/messageCenter/messageDetail'))
const ProcessCanvasCreate = lazy(() => import('@/views/processCanvas/create'))
const CampaignDetail = lazy(() => import('@/views/processCanvas/detail'))
const QuickStart = lazy(() => import('@/views/quickStart'))
const SMSDetail = lazy(() => import('@/views/smsList/detail'))
const EmailDetail = lazy(() => import('@/views/emailList/detail'))
const AuthLoading = lazy(() => import('@/views/authLoading'))

export interface RouteObject {
  caseSensitive?: boolean
  children?: RouteObject[]
  element?: React.ReactNode
  index?: boolean
  path?: string
  breadcrumbName?: string
  Navigate?: string
  key?: string
}

let routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      { index: true, element: <QuickStart /> },
      { path: 'home', element: <Home /> },
      {
        path: 'template-management',
        breadcrumbName: 'Templates',
        children: [
          { index: true, element: <EmailList /> },
          {
            path: 'email-list',
            breadcrumbName: 'Email Template',
            children: [
              { index: true, element: <EmailList /> },
              { path: 'add-email', element: <AddOrEditEmail />, breadcrumbName: 'Add Email Template' },
              { path: 'edit-email', element: <AddOrEditEmail />, breadcrumbName: 'Edit Email Template' },
              { path: 'email-detail', element: <EmailDetail />, breadcrumbName: 'Email Template Detail' },
            ],
          },
          {
            path: 'sms-list',
            breadcrumbName: 'SMS Template',
            children: [
              { index: true, element: <SMSList /> },
              { path: 'add-sms', element: <AddOrEditSMS />, breadcrumbName: 'Create SMS Template' },
              { path: 'edit-sms', element: <AddOrEditSMS />, breadcrumbName: 'Edit SMS Template' },
              { path: 'sms-detail', element: <SMSDetail />, breadcrumbName: 'SMS Template Detail' },
            ],
          },
        ],
      },
      {
        path: 'message-center',
        breadcrumbName: 'Message Center',
        children: [
          { index: true, element: <MessageList /> },
          {
            path: 'message-list',
            breadcrumbName: 'Message list',
            children: [
              { index: true, element: <MessageList /> },
              { path: 'message-detail', element: <MessageDetail />, breadcrumbName: 'Message Detail' },
            ],
          },
        ],
      },
      {
        path: 'campaign-plan',
        breadcrumbName: 'Campaign Plan',
        children: [{ index: true, element: <CompaignPlan currentType='COMPAIGNPLAN' processCategory='' /> }],
      },
      {
        path: 'smart-operation',
        breadcrumbName: 'Smart Operation',
        children: [
          { index: true, element: <CompaignPlan currentType='QUICKTASK' processCategory='TASK' /> },
          {
            path: 'quick-task',
            breadcrumbName: 'Quick Task',
            children: [
              { index: true, element: <CompaignPlan currentType='QUICKTASK' processCategory='TASK' /> },
              { path: 'create-task', element: <TaskCreateAndEdit pageStatus={'create'} />, breadcrumbName: 'Create Task' },
              { path: 'edit-task', element: <TaskCreateAndEdit pageStatus={'update'} />, breadcrumbName: 'Edit Task' },
              { path: 'task-detail', element: <TaskCreateAndEdit pageStatus={'detail'} />, breadcrumbName: 'Task Detail' },
            ],
          },
          {
            path: 'process-canvas',
            breadcrumbName: 'Process Canvas',
            children: [
              { index: true, element: <CompaignPlan currentType='PROCESSCANVAS' processCategory='CAMPAIGN' /> },
              { path: 'create', element: <ProcessCanvasCreate />, breadcrumbName: 'Create Process Canvas' },
              { path: 'detail', element: <CampaignDetail />, breadcrumbName: 'Campaign Detail' },
            ],
          },
        ],
      },
    ],
    key: 'store',
  },
  { path: '/authloading', element: <AuthLoading />, key: 'store' },
  { path: '/login', element: <Login />, key: 'store' },
  { path: '/store/select', element: <SelectStore />, key: 'store' },
  { path: '/login/store', element: <LoginStore />, key: 'store' },
  { path: '/login/brand', element: <LoginBrand />, key: 'store' },
  { path: '/resetPassword', element: <ResetPassword />, key: 'store' },
  { path: '/register', element: <Register />, key: 'store' },
  { path: '*', element: <Home />, key: 'store' },
]

// The useRoutes() hook allows you to define your routes as JavaScript objects
// instead of <Routes> and <Route> elements. This is really just a style
// preference for those who prefer to not use JSX for their routes config.

export default routes
